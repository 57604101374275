// **  Initial State
const initialState = {
  countDeposit: 0,
  countWithdraw: 0,
  countCommission: 0,
  countDepositUsdt: 0,
  countWithdrawUsdt: 0,
  reload: true,
  isCheckSound: false,
  isClickAll: false
}

const historyTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COUNT_DEPOSIT':
      return {
        ...state,
        countDeposit: action.data
      }

    case 'COUNT_WITHDRAW':
      return {
        ...state,
        countWithdraw: action.data
      }
    case 'COUNT_DEPOSIT_USDT':
      return {
        ...state,
        countDepositUsdt: action.data
      }

    case 'COUNT_WITHDRAW_USDT':
      return {
        ...state,
        countWithdrawUsdt: action.data
      }
    case 'COUNT_COMMISSION':
      return {
        ...state,
        countCommission: action.data
      }
    case 'RELOAD_COUNT':
      return {
        ...state,
        reload: action.data
      }
    case 'CHECK_SOUND':
      return {
        ...state,
        isCheckSound: action.data
      }
    case 'IS_CLICK_ALL':
      return {
        ...state,
        isClickAll: action.data
      }
    default:
      return state
  }
}

export default historyTransactionReducer
