// **  Initial State
const initialState = {
  currentTransaction: undefined,
  servertime: 0,
  newResultRecord: undefined
}

const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LIVE_USER_RECORD_008':
      return {
        ...state,
        currentTransaction: action.data
      }
    case 'SERVER_TIME':
      return {
        ...state,
        servertime: action.data
      }
    case 'BINARYOPTION':
      return {
        ...state,
        newResultRecord: action.data
      }
    default:
      return state
  }
}

export default socketReducer
