import * as Yup from 'yup'

Yup.addMethod(Yup.mixed, 'username', function (message) {
  return this.test('username', message, function (value) {
    const { path, createError } = this

    if (!value) {
      return createError({ path, message: message ?? 'Vui lòng nhập tài khoản' })
    }

    if (value.length < 6) {
      return createError({ path, message: message ?? 'Tài khoản phải chứa ít nhất 6 ký tự' })
    }

    if (!value.match(/^[a-zA-Z0-9]+$/)) {
      return createError({
        path,
        message: message ?? 'Tên tài khoản không chứa kí tự hoặc khoảng cách'
      })
    }

    return true
  })
})

Yup.addMethod(Yup.string, 'phoneNumber', function () {
  return this.min(8, 'Số điện thoại 8 - 12 số').max(12, 'Số điện thoại 8 - 12 số').required('Vui lòng nhập Số điện thoại').nullable()
})
